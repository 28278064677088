import { HTTP } from '@/service/http-commom';

class ClusterService {
  async getClusters({ page = 1 }) {
    return await HTTP.get('/clusters', {
      params: {
        page,
      },
    }).then((response) => {
      return response.data;
    });
  }
  async getCluster(clusterId) {
    return await HTTP.get(`/clusters/${clusterId}`).then((response) => {
      return response.data;
    });
  }
  async storeClusterRule({ clusterId, type, value }) {
    return await HTTP.post(`/clusters/${clusterId}/rules`, {
      type,
      value,
    }).then((response) => {
      return response.data;
    });
  }
  async removeClusterRule({ clusterId, ruleId }) {
    return await HTTP.delete(`/clusters/${clusterId}/rules/${ruleId}`).then(
      (response) => {
        return response.data;
      },
    );
  }
}

export default new ClusterService();
