import { createRouter, createWebHistory } from 'vue-router';
import { LOCAL_STORAGE_TOKEN_NAME } from '@/helper/application';
import LoginView from '@/views/LoginView.vue';
import DashboardView from '@/views/DashboardView';

//lazy-loaded
const ClientView = () => import('@/views/clients/ClientView');
const ClientListView = () => import('@/views/clients/ClientListView');
const ClientResumeView = () => import('@/views/clients/ClientResumeView');
const ClientFormView = () => import('@/views/clients/ClientFormView');

const DeviceView = () => import('@/views/devices/DeviceView');
const DeviceListView = () => import('@/views/devices/DeviceListView');
const DeviceResumeView = () => import('@/views/devices/DeviceResumeView');

const ShopView = () => import('@/views/shops/ShopView');
const ShopListView = () => import('@/views/shops/ShopListView');
const ShopResumeView = () => import('@/views/shops/ShopResumeView');
const ShopFormView = () => import('@/views/shops/ShopFormView');

const SettingView = () => import('@/views/settings/SettingView');
const SettingClustersView = () =>
  import('@/views/settings/SettingsClusterView');
const SettingClusterResumeView = () =>
  import('@/views/settings/SettingClusterResumeView');

const ResultView = () => import('@/views/results/ResultView');
const ResultListView = () => import('@/views/results/ResultListView');
const ResultFormView = () => import('@/views/results/ResultFormView');
const ResultResumeView = () => import('@/views/results/ResultResumeView');

const routes = [
  {
    path: '/login',
    name: 'login',
    component: LoginView,
  },
  {
    path: '/',
    name: 'dashboard',
    meta: { acl: ['root', 'admin', 'result_operator'] },
    component: DashboardView,
  },
  {
    path: '/clients',
    component: ClientView,
    meta: { acl: ['root', 'admin'] },
    children: [
      {
        path: '',
        fullName: 'Clientes',
        name: 'clients_list',
        meta: { acl: ['root', 'admin'] },
        component: ClientListView,
      },
      {
        path: ':id/resume',
        fullName: 'Clientes',
        name: 'clients_resume',
        meta: { acl: ['root', 'admin'] },
        component: ClientResumeView,
      },
      {
        path: ':id/edit',
        fullName: 'Clientes',
        name: 'clients_edit',
        meta: { acl: ['root', 'admin'] },
        component: ClientFormView,
      },
      {
        path: 'create',
        fullName: 'Clientes',
        name: 'clients_create',
        meta: { acl: ['root', 'admin'] },
        component: ClientFormView,
      },
    ],
  },
  {
    path: '/shops',
    component: ShopView,
    meta: { acl: ['root', 'admin'] },
    children: [
      {
        path: '',
        name: 'shops_list',
        meta: { acl: ['root', 'admin'] },
        component: ShopListView,
      },
      {
        path: ':id/resume',
        name: 'shops_resume',
        meta: { acl: ['root', 'admin'] },
        component: ShopResumeView,
      },
      {
        path: 'create',
        name: 'shops_create',
        meta: { acl: ['root', 'admin'] },
        component: ShopFormView,
      },
    ],
  },
  {
    path: '/devices',
    component: DeviceView,
    meta: { acl: ['root', 'admin'] },
    children: [
      {
        path: '',
        name: 'devices_list',
        meta: { acl: ['root', 'admin'] },
        component: DeviceListView,
      },
      {
        path: ':id/resume',
        name: 'devices_resume',
        meta: { acl: ['root', 'admin'] },
        component: DeviceResumeView,
      },
    ],
  },
  {
    path: '/settings',
    component: SettingView,
    meta: { acl: ['root'] },
    children: [
      {
        path: '/clusters',
        name: 'clusters_list',
        meta: { acl: ['root'] },
        component: SettingClustersView,
      },
      {
        path: '/clusters/:id',
        name: 'cluster_resume',
        meta: { acl: ['root'] },
        component: SettingClusterResumeView,
      },
    ],
  },
  {
    path: '/results',
    component: ResultView,
    meta: { acl: ['root', 'admin', 'result_operator'] },
    children: [
      {
        path: '/results',
        name: 'results_list',
        meta: { acl: ['root', 'admin', 'result_operator'] },
        component: ResultListView,
      },
      {
        path: ':id',
        name: 'results_resume',
        meta: { acl: ['root', 'admin', 'result_operator'] },
        component: ResultResumeView,
      },
      {
        path: 'create',
        fullName: 'Resultados',
        name: 'results_create',
        meta: { acl: ['root', 'admin', 'result_operator'] },
        component: ResultFormView,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const publicPages = ['/login'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem(LOCAL_STORAGE_TOKEN_NAME);

  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    next('/login');
  } else {
    if (to.path === '/login') {
      next();
      return;
    }

    const userData = JSON.parse(localStorage.getItem(LOCAL_STORAGE_TOKEN_NAME));

    if (!to.meta.acl.includes(userData.user.type)) {
      next('/'); //redir to dashboard
      return;
    }

    next();
  }
});

export default router;
