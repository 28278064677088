<template>
  <div id="app">
    <NavbarMenu v-if="loggedIn" />
    <div class="container">
      <router-view />
      <FooterMenu v-if="loggedIn" />
    </div>
  </div>
</template>

<script>
import NavbarMenu from '@/components/Navbar';
import FooterMenu from '@/components/Footer';

export default {
  components: { FooterMenu, NavbarMenu },
  created() {
    this.$watch(
      () => this.$route.query,
      () => {
        this.fetchRealIp();
      },
      { immediate: true },
    );
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  methods: {
    fetchRealIp() {
      this.$store.dispatch('auth/getRealIp').then(
        () => {},
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          console.log(message);
        },
      );
    },
  },
};
</script>
