import resultService from '@/service/result/result.service';

const initialState = {
  data: [],
  result: {},
  meta: {
    total: 0,
    per_page: 15,
    current_page: 1,
    last_page: 1,
    first_page: 1,
    first_page_url: '/?page=1',
    last_page_url: '/?page=1',
    next_page_url: null,
    previous_page_url: null,
  },
};

export const result = {
  namespaced: true,
  state: initialState,
  actions: {
    loadLotteries(_, date) {
      return resultService.getLotteries(date).then(
        (data) => {
          return Promise.resolve(data);
        },
        (error) => {
          return Promise.reject(error);
        },
      );
    },
    createResult(_, resultData) {
      return resultService.createResult(resultData).then(
        (data) => {
          return Promise.resolve(data);
        },
        (error) => {
          return Promise.reject(error);
        },
      );
    },

    loadLotteriesList({ commit }, { page = 1, date }) {
      return resultService.getLotteriesList({ page, date }).then(
        (data) => {
          commit('setLotteriesData', data);
          return Promise.resolve(data);
        },
        (error) => {
          return Promise.reject(error);
        },
      );
    },

    loadResult({ commit }, resultId) {
      return resultService.getResult(resultId).then(
        (data) => {
          commit('setResultData', data);
          return Promise.resolve(data);
        },
        (error) => {
          return Promise.reject(error);
        },
      );
    },

    resultRetry(_, resultId) {
      return resultService.resultRetry(resultId).then(
        (data) => {
          return Promise.resolve(data);
        },
        (error) => {
          return Promise.reject(error);
        },
      );
    },

    resultRemove(_, resultId) {
      return resultService.resultRemove(resultId).then(
        (data) => {
          return Promise.resolve(data);
        },
        (error) => {
          return Promise.reject(error);
        },
      );
    },
  },
  mutations: {
    setLotteriesData(state, payload) {
      state.data = payload.data;
      state.meta = payload.meta;
    },
    setResultData(state, payload) {
      state.result = payload.data;
    },
  },
};
