import { HTTP } from '@/service/http-commom';

class ResultService {
  async getLotteries(date) {
    return await HTTP.get(`/lotteries?date=${date}`).then((response) => {
      return response.data;
    });
  }

  async getLotteriesList({ page, date }) {
    return await HTTP.get(`/results?page=${page}&date=${date}`).then(
      (response) => response.data,
    );
  }

  async createResult(requestData) {
    return await HTTP.post('/results', requestData).then((response) => {
      return response.data;
    });
  }

  async getResult(resultId) {
    return await HTTP.get(`/results/${resultId}`).then((response) => {
      return response.data;
    });
  }

  async resultRetry(resultId) {
    return await HTTP.post(`/results/retry`, { result_id: resultId }).then(
      (response) => {
        return response.data;
      },
    );
  }

  async resultRemove(resultId) {
    return await HTTP.post(`/results/remove`, { result_id: resultId }).then(
      (response) => {
        return response.data;
      },
    );
  }
}

export default new ResultService();
