import 'bootstrap/dist/css/bootstrap.css';
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import Toast, { POSITION } from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import dayjs from 'dayjs';

import { FontAwesomeIcon } from './plugins/font-awesome';

const app = createApp(App);
app.use(store);
app.use(router);
app.use(Toast, { position: POSITION.TOP_RIGHT, timeout: 2000 });

app.component('font-awesome-icon', FontAwesomeIcon);

app.config.globalProperties.$dayjs = dayjs;

app.mount('#app');

import 'bootstrap/dist/js/bootstrap.bundle';
