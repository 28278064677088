import { HTTP } from '@/service/http-commom';

class DeviceService {
  async getDevices({ page = 1, search = null }) {
    return await HTTP.get('/devices', {
      params: {
        page,
        search,
      },
    }).then((response) => {
      return response.data;
    });
  }
  async updateDevice({ deviceId, enableUseInVirtualDevice }) {
    const requestData = {
      enable_use_in_virtual_device: enableUseInVirtualDevice,
    };

    return await HTTP.put(`/devices/${deviceId}`, requestData).then(
      (response) => {
        return response.data;
      },
    );
  }
  async getDevice(deviceId) {
    return await HTTP.get(`/devices/${deviceId}/info`).then((response) => {
      return response.data;
    });
  }
  async resetDevice(deviceId) {
    return await HTTP.post(`/devices/${deviceId}/reset`).then((response) => {
      return response.data;
    });
  }
}

export default new DeviceService();
