<template>
  <footer
    class="d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top"
  >
    <div class="col-md-4 d-flex align-items-center">
      <a
        href="/"
        class="mb-3 me-2 mb-md-0 text-muted text-decoration-none lh-1"
      >
        <img
          src="@/assets/logo.png"
          alt="Guardian logo"
          width="24"
          height="24"
          class="d-inline-block align-text-top"
        />
        Guardian
      </a>
    </div>

    <div class="nav col-md-4 justify-content-end list-unstyled d-flex">
      Ip: {{ currentIp }}
    </div>
  </footer>
</template>

<script>
export default {
  name: 'FooterMenu',
  computed: {
    currentIp() {
      return this.$store.state.auth.current_ip;
    },
  },
};
</script>

<style scoped></style>
