import clientService from '@/service/client/client.service';

const initialState = {
  data: [],
  client: {
    id: null,
    name: '',
    email: '',
    phone: '',
    created_at: '',
    updated_at: '',
    shops: [],
  },
  meta: {
    total: 0,
    per_page: 15,
    current_page: 1,
    last_page: 1,
    first_page: 1,
    first_page_url: '/?page=1',
    last_page_url: '/?page=1',
    next_page_url: null,
    previous_page_url: null,
  },
};

export const client = {
  namespaced: true,
  state: initialState,
  actions: {
    loadClients({ commit }, { page = 1, searchTerm = null }) {
      searchTerm = searchTerm
        ? new URLSearchParams(searchTerm).toString()
        : null;
      return clientService.getClients({ page, search: searchTerm }).then(
        (data) => {
          commit('setClientsData', data);
          return Promise.resolve(data);
        },
        (error) => {
          return Promise.reject(error);
        },
      );
    },
    loadClient({ commit }, clientId) {
      return clientService.getClient(clientId).then(
        (data) => {
          commit('setClientData', data);
          return Promise.resolve(data);
        },
        (error) => {
          return Promise.reject(error);
        },
      );
    },
    loadClientShops({ commit }, clientId) {
      return clientService.getClientShops(clientId).then(
        (data) => {
          commit('setClientShopsData', data);
          return Promise.resolve(data);
        },
        (error) => {
          return Promise.reject(error);
        },
      );
    },
    createClient(_, userData) {
      const { name, email, phone } = userData;

      return clientService.createClient({ name, email, phone }).then(
        (data) => {
          return Promise.resolve(data);
        },
        (error) => {
          return Promise.reject(error);
        },
      );
    },
  },
  mutations: {
    setClientsData(state, payload) {
      state.data = payload.data;
      state.meta = payload.meta;
    },
    setClientData(state, data) {
      state.client = data;
      state.client.shops = [];
    },
    setClientShopsData(state, data) {
      state.client.shops = data;
    },
  },
};
