<template>
  <div class="d-flex justify-content-center align-items-center vh-100">
    <div class="row justify-content-center w-100">
      <div class="col-sm-12 col-md-5 col-lg-5 col-xxl-4">
        <div class="card">
          <div
            class="card-header d-flex justify-content-center align-items-center"
          >
            <img
              src="@/assets/logo.png"
              alt="Guardian logo"
              width="28"
              height="28"
              class="d-inline-block align-text-top"
            />
            <span class="fs-4 fw-bold ms-3">Guardian</span>
          </div>
          <div class="card-body">
            <Form
              @submit="handleLogin"
              :validation-schema="validationSchema"
              class="needs-validation"
            >
              <div class="mb-3">
                <label for="email" class="form-label">Usuário</label>
                <Field
                  type="email"
                  class="form-control"
                  id="email"
                  name="email"
                  placeholder="name@example.com"
                  autofocus
                  required
                />
                <ErrorMessage name="email" class="text-danger fs-11" />
              </div>

              <div class="mb-3">
                <label for="password" class="form-label">Senha</label>
                <Field
                  type="password"
                  class="form-control"
                  id="password"
                  name="password"
                  placeholder="sua senha"
                  required
                />
                <ErrorMessage name="password" class="text-danger fs-11" />
              </div>

              <div class="mb-3">
                <button
                  class="btn btn-primary w-100"
                  type="submit"
                  :disabled="loading"
                >
                  <span
                    v-show="loading"
                    class="spinner-border spinner-border-sm"
                  ></span>
                  <span>Entrar</span>
                </button>
              </div>

              <div class="form-group">
                <div v-if="message" class="alert alert-danger" role="alert">
                  {{ message }}
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';
import authService from '@/service/auth/auth.service';

export default {
  name: 'LoginView',
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    const validationSchema = yup.object().shape({
      email: yup
        .string()
        .required('E-mail é obrigatório!')
        .email('E-mail inválido.'),
      password: yup
        .string()
        .required('Senha é obrigatória!')
        .min(3, 'Mínimo 3 caracteres'),
    });

    return {
      loading: false,
      message: '',
      validationSchema,
    };
  },
  computed: {
    loggedIn() {
      return (
        authService.isValidStorageToken() &&
        this.$store.state.auth.status.loggedIn
      );
    },
  },
  created() {
    if (this.loggedIn) {
      this.$router.push('/');
    }
  },
  methods: {
    handleLogin(user) {
      this.loading = true;
      this.$store.dispatch('auth/login', user).then(
        () => {
          this.$router.push('/');
        },
        (error) => {
          this.loading = false;
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        },
      );
    },
  },
};
</script>
