import { LOCAL_STORAGE_TOKEN_NAME } from '@/helper/application';

export default function authHeader() {
  let userData = JSON.parse(localStorage.getItem(LOCAL_STORAGE_TOKEN_NAME));

  if (userData && userData.token) {
    return { Authorization: 'Bearer ' + userData.token };
  }

  return {};
}
