import deviceService from '@/service/device/device.service';

const initialState = {
  data: [],
  device: {
    id: null,
    name: '',
    device_token: '',
    request_endpoint: '',
    request_token: '',
    is_active: false,
    enable_use_in_virtual_device: false,
    shop: null,
    created_at: '',
    updated_at: '',
  },
  meta: {
    total: 0,
    per_page: 15,
    current_page: 1,
    last_page: 1,
    first_page: 1,
    first_page_url: '/?page=1',
    last_page_url: '/?page=1',
    next_page_url: null,
    previous_page_url: null,
  },
};

export const device = {
  namespaced: true,
  state: initialState,
  actions: {
    loadDevices({ commit }, { page = 1, searchTerm = null }) {
      searchTerm = searchTerm
        ? new URLSearchParams(searchTerm).toString()
        : null;
      return deviceService.getDevices({ page, search: searchTerm }).then(
        (data) => {
          commit('setDevicesData', data);
          return Promise.resolve(data);
        },
        (error) => {
          return Promise.reject(error);
        },
      );
    },
    loadDevice({ commit }, deviceId) {
      return deviceService.getDevice(deviceId).then(
        (data) => {
          commit('setDeviceData', data);
          return Promise.resolve(data);
        },
        (error) => {
          return Promise.reject(error);
        },
      );
    },
    updateDevice(_, { deviceId, enableUseInVirtualDevice }) {
      return deviceService
        .updateDevice({ deviceId, enableUseInVirtualDevice })
        .then(
          (data) => {
            return Promise.resolve(data);
          },
          (error) => {
            return Promise.reject(error);
          },
        );
    },
    resetDevice({ commit }, deviceId) {
      return deviceService.resetDevice(deviceId).then(
        (data) => {
          commit('setDeviceData', data);
          return Promise.resolve(data);
        },
        (error) => {
          return Promise.reject(error);
        },
      );
    },
  },
  mutations: {
    setDevicesData(state, payload) {
      state.data = payload.data;
      state.meta = payload.meta;
    },
    setDeviceData(state, data) {
      state.device = data;
    },
  },
};
