import { HTTP } from '@/service/http-commom';

class ShopService {
  async getShops({ page = 1, search = null }) {
    return await HTTP.get('/shops', {
      params: {
        page,
        search,
      },
    }).then((response) => {
      return response.data;
    });
  }
  async getShop(clientId) {
    return await HTTP.get(`/shops/${clientId}`).then((response) => {
      return response.data;
    });
  }
  async requestSupportSession(clientId) {
    return await HTTP.post(
      `/shops/${clientId}/request-support-direct-login`,
    ).then((response) => {
      return response.data;
    });
  }
  async createShop({ shop_name, owner_name, owner_email, owner_phone }) {
    const requestData = {
      shop_name,
      owner_name,
      owner_email,
      owner_phone,
    };

    return await HTTP.post('/shops', requestData).then((response) => {
      return response.data;
    });
  }
}

export default new ShopService();
