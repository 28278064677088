<template>
  <nav class="navbar navbar-expand-lg navbar-dark bg-dark sticky-top">
    <div class="container">
      <a class="navbar-brand" href="#">
        <img
          src="@/assets/logo.png"
          alt="Guardian logo"
          width="28"
          height="28"
          class="d-inline-block align-text-top"
        />
        Guardian
      </a>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <router-link to="/" class="nav-link">
              <font-awesome-icon
                :icon="{ prefix: 'fas', iconName: 'dashboard' }"
                fixed-width
                size="sm"
              />
              Home
            </router-link>
          </li>
          <li
            v-if="['root', 'admin'].includes(loggedUserType)"
            class="nav-item"
          >
            <router-link to="/shops" class="nav-link">
              <font-awesome-icon
                :icon="{ prefix: 'fas', iconName: 'shop' }"
                fixed-width
                size="sm"
              />
              Bancas
            </router-link>
          </li>
          <li
            v-if="['root', 'admin'].includes(loggedUserType)"
            class="nav-item"
          >
            <router-link to="/clients" class="nav-link">
              <font-awesome-icon
                :icon="{ prefix: 'fas', iconName: 'users' }"
                fixed-width
                size="sm"
              />
              Clientes
            </router-link>
          </li>
          <li
            v-if="['root', 'admin'].includes(loggedUserType)"
            class="nav-item"
          >
            <router-link to="/devices" class="nav-link">
              <font-awesome-icon
                :icon="{ prefix: 'fas', iconName: 'mobile-screen' }"
                fixed-width
                size="sm"
              />
              Dispositivos
            </router-link>
          </li>
          <li v-if="['root'].includes(loggedUserType)" class="nav-item">
            <router-link :to="{ name: 'clusters_list' }" class="nav-link">
              <font-awesome-icon
                :icon="{ prefix: 'fas', iconName: 'gears' }"
                fixed-width
                size="sm"
              />
              Configurações
            </router-link>
          </li>
          <li
            v-if="['root', 'admin', 'result_operator'].includes(loggedUserType)"
            class="nav-item"
          >
            <router-link to="/results" class="nav-link">
              <font-awesome-icon
                :icon="{ prefix: 'fas', iconName: 'circle-check' }"
                fixed-width
                size="sm"
              />
              Resultados
            </router-link>
          </li>
        </ul>
        <div class="d-flex justify-content-center align-items-center">
          <p class="text-white mb-0 me-3">{{ currentUser.email }}</p>
          <button
            class="btn btn-warning"
            @click.prevent="handleLogout"
            type="button"
            :disabled="loading"
          >
            <span
              v-show="loading"
              class="spinner-border spinner-border-sm"
            ></span>
            <span>Sair</span>
          </button>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { useToast } from 'vue-toastification';

export default {
  name: 'NavbarMenu',
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    loggedUserType() {
      return this.$store.state.auth.user.type;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  methods: {
    handleLogout() {
      this.loading = true;
      this.$store
        .dispatch('auth/logout')
        .then(
          () => {
            this.$router.push('/login');
          },
          () => {
            this.toast.error("I'm an info toast!");
          },
        )
        .finally(() => (this.loading = false));
    },
  },
};
</script>

<style scoped></style>
