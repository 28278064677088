import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faAndroid,
  faDigitalOcean,
  faWhatsapp,
} from '@fortawesome/free-brands-svg-icons';
import {
  faAngleLeft,
  faArrowUpRightFromSquare,
  faCalendarDay,
  faCheck,
  faCircleCheck,
  faCircleNodes,
  faCircleQuestion,
  faCircleStop,
  faClock,
  faCode,
  faDashboard,
  faDatabase,
  faDroplet,
  faEnvelope,
  faEthernet,
  faEye,
  faGears,
  faHouseSignal,
  faIdCard,
  faIndustry,
  faKey,
  faLaptopCode,
  faLink,
  faLocationPin,
  faMobileScreen,
  faPhone,
  faPlus,
  faSatelliteDish,
  faServer,
  faShop,
  faTag,
  faTerminal,
  faTrash,
  faUser,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(
  faIdCard,
  faEnvelope,
  faPhone,
  faServer,
  faClock,
  faDroplet,
  faEthernet,
  faDatabase,
  faShop,
  faLink,
  faCalendarDay,
  faKey,
  faGears,
  faUser,
  faCircleCheck,
  faCheck,
  faCircleStop,
  faCircleQuestion,
  faTrash,
  faEye,
  faUsers,
  faDashboard,
  faAngleLeft,
  faCircleNodes,
  faWhatsapp,
  faDigitalOcean,
  faLaptopCode,
  faLocationPin,
  faTag,
  faPlus,
  faMobileScreen,
  faTerminal,
  faHouseSignal,
  faSatelliteDish,
  faAndroid,
  faIndustry,
  faCode,
  faArrowUpRightFromSquare,
);

export { FontAwesomeIcon };
