import authService from '@/service/auth/auth.service';
import { LOCAL_STORAGE_TOKEN_NAME } from '@/helper/application';

const tokenData = JSON.parse(localStorage.getItem(LOCAL_STORAGE_TOKEN_NAME));
const user = tokenData?.user;

const initialState = user
  ? { status: { loggedIn: true }, user, current_ip: null }
  : { status: { loggedIn: false }, user: null, current_ip: null };

export const auth = {
  namespaced: true,
  state: initialState,
  actions: {
    login({ commit }, { email, password }) {
      return authService.login({ email, password }).then(
        (data) => {
          commit('loginSuccess', data);
          return Promise.resolve(data);
        },
        (error) => {
          commit('loginFailure');
          return Promise.reject(error);
        },
      );
    },
    getUserData({ commit }) {
      return authService.getUserData().then(
        (data) => {
          commit('loginSuccess', data);
          return Promise.resolve(data);
        },
        (error) => {
          commit('loginFailure');
          return Promise.reject(error);
        },
      );
    },
    getRealIp({ commit }) {
      return authService.getRealIp().then((data) => {
        commit('setCurrentIp', data);
        return Promise.resolve(data);
      });
    },
    logout({ commit }) {
      authService.logout();
      commit('logout');
    },
  },
  mutations: {
    loginSuccess(state, data) {
      state.status.loggedIn = true;
      state.user = data.user;
    },
    loginFailure(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    logout(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    setCurrentIp(state, data) {
      state.current_ip = data;
    },
  },
};
