import { createStore } from 'vuex';
import { auth } from './auth.module';
import { client } from './client/client.module';
import { device } from './device/device.module';
import { shop } from './shop/shop.module';
import { cluster } from './cluster/cluster.module';
import { result } from './result/result.module';

export default createStore({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: { auth, client, device, cluster, shop, result },
});
