import { HTTP } from '@/service/http-commom';

class ClientService {
  async getClients({ page = 1, search = null }) {
    return await HTTP.get('/clients', {
      params: {
        page,
        search,
      },
    }).then((response) => {
      return response.data;
    });
  }
  async getClient(clientId) {
    return await HTTP.get(`/clients/${clientId}`).then((response) => {
      return response.data;
    });
  }
  async getClientShops(clientId) {
    return await HTTP.get(`/clients/${clientId}/shops`).then((response) => {
      return response.data;
    });
  }
  async createClient({ name, email, phone }) {
    return await HTTP.post('/clients', { name, email, phone }).then(
      (response) => {
        return response.data;
      },
    );
  }
}

export default new ClientService();
