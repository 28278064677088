import shopService from '@/service/shop/shop.service';

const initialState = {
  data: [],
  shop: {
    id: null,
    name: '',
    request_token: '',
    client_id: null,
    slug: '',
    app_db_name: '',
    droplet_status: '',
    is_active: true,
    endpoint: '',
    created_at: '',
    updated_at: '',
    owner: null,
  },
  meta: {
    total: 0,
    per_page: 15,
    current_page: 1,
    last_page: 1,
    first_page: 1,
    first_page_url: '/?page=1',
    last_page_url: '/?page=1',
    next_page_url: null,
    previous_page_url: null,
  },
};

export const shop = {
  namespaced: true,
  state: initialState,
  actions: {
    loadShops({ commit }, { page = 1, searchTerm = null }) {
      searchTerm = searchTerm
        ? new URLSearchParams(searchTerm).toString()
        : null;
      return shopService.getShops({ page, search: searchTerm }).then(
        (data) => {
          commit('setShopsData', data);
          return Promise.resolve(data);
        },
        (error) => {
          return Promise.reject(error);
        },
      );
    },
    loadShop({ commit }, shopId) {
      return shopService.getShop(shopId).then(
        (data) => {
          commit('setShopData', data);
          return Promise.resolve(data);
        },
        (error) => {
          return Promise.reject(error);
        },
      );
    },
    requestSupportSession(_, shopId) {
      return shopService.requestSupportSession(shopId).then(
        (data) => {
          return Promise.resolve(data);
        },
        (error) => {
          return Promise.reject(error);
        },
      );
    },
    createShop(_, shopData) {
      const { shop_name, owner_name, owner_email, owner_phone } = shopData;

      return shopService
        .createShop({ shop_name, owner_name, owner_email, owner_phone })
        .then(
          (data) => {
            return Promise.resolve(data);
          },
          (error) => {
            return Promise.reject(error);
          },
        );
    },
  },
  mutations: {
    setShopsData(state, payload) {
      state.data = payload.data;
      state.meta = payload.meta;
    },
    setShopData(state, data) {
      state.shop = data;
    },
  },
};
