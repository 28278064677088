import clusterService from '@/service/cluster/cluster.service';

const initialState = {
  data: [],
  cluster: {
    id: '',
    name: '',
    engine: '',
    status: '',
    size: '',
    region: '',
    version: '',
    rules: [],
  },
};

export const cluster = {
  namespaced: true,
  state: initialState,
  actions: {
    loadClusters({ commit }, { page = 1 }) {
      return clusterService.getClusters({ page }).then(
        (data) => {
          commit('setClustersData', data);
          return Promise.resolve(data);
        },
        (error) => {
          return Promise.reject(error);
        },
      );
    },
    loadCluster({ commit }, clusterId) {
      return clusterService.getCluster(clusterId).then(
        (data) => {
          commit('setClusterData', data);
          return Promise.resolve(data);
        },
        (error) => {
          return Promise.reject(error);
        },
      );
    },
    storeClusterRule(_, { clusterId, type, value }) {
      return clusterService.storeClusterRule({ clusterId, type, value }).then(
        (data) => {
          return Promise.resolve(data);
        },
        (error) => {
          return Promise.reject(error);
        },
      );
    },
    removeClusterRule(_, { clusterId, ruleId }) {
      return clusterService.removeClusterRule({ clusterId, ruleId }).then(
        (data) => {
          return Promise.resolve(data);
        },
        (error) => {
          return Promise.reject(error);
        },
      );
    },
  },
  mutations: {
    setClustersData(state, payload) {
      state.data = payload;
    },
    setClusterData(state, data) {
      state.cluster = data;
    },
  },
};
