import { HTTP } from '@/service/http-commom';
import { LOCAL_STORAGE_TOKEN_NAME } from '@/helper/application';
import { IPIFY } from '@/service/http-ipfy';

class AuthService {
  async login({ email, password }) {
    return await HTTP.post('/auth/login', {
      email,
      password,
    }).then((response) => {
      if (response.data.token) {
        localStorage.setItem(
          LOCAL_STORAGE_TOKEN_NAME,
          JSON.stringify(response.data),
        );
      }
      return response.data;
    });
  }
  async getUserData() {
    return await HTTP.get('/auth/me').then((response) => {
      if (response.data.token) {
        localStorage.setItem(
          LOCAL_STORAGE_TOKEN_NAME,
          JSON.stringify(response.data),
        );
      }
      return response.data;
    });
  }
  async getRealIp() {
    return await IPIFY.get('/').then((response) => {
      return response.data;
    });
  }
  isValidStorageToken() {
    const tokenData = JSON.parse(
      localStorage.getItem(LOCAL_STORAGE_TOKEN_NAME),
    );
    return tokenData?.user !== null;
  }
  logout() {
    localStorage.removeItem(LOCAL_STORAGE_TOKEN_NAME);
  }
}
export default new AuthService();
