import axios from 'axios';
import authHeader from './auth/auth-header';

const isProdEnv = process.env.NODE_ENV === 'production';
const protocol = isProdEnv ? 'https' : 'http';

//check if contains API_URL
const apiURL = process.env.APP_API_URL || 'api-guardian.quantumsistemas.app';

const url = isProdEnv ? apiURL : 'localhost:3333';

export const HTTP = axios.create({
  baseURL: `${protocol}://${url}/api`,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': `${protocol}://${url}`,
    ...authHeader(),
  },
});
